.overlay {
  position: fixed;
  left: 0;
  top: 46.8px;
  bottom: 0;
  right: 0;
  z-index: 30;
  pointer-events: none;
  &.is-correction {
    border: solid 10px #ffdd57;
  }
}

.underlay {
  position: fixed;
  left: 0;
  top: 46.8px;
  bottom: 0;
  right: 0;
  z-index: -1;
  pointer-events: none;
  &.is-correction {
    background-color: rgba(255, 221, 87, 0.25);
  }
  &.is-flash {
    background-color: $mlb-blue;
  }
}
