.responsive-pitch-fx {
  background-position: center top, center bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: 100% 55%, 100% 46%;
}

.responsive-pitch-fx {
  position: relative;
  overflow: hidden;
  width: 100%;
  left: 0;
  z-index: 1;
}
.responsive-pitch-fx.responsive-pitch-fx-offscreen {
  display: block;
  visibility: hidden;
  position: fixed;
  z-index: -99999999999;
}
.responsive-pitch-fx .responsive-pitch-fx-aspect-ratio {
  width: 100%;
  height: auto;
  z-index: 1;
}
.responsive-pitch-fx-tilt-shift.responsive-pitch-fx
  .responsive-pitch-fx-aspect-ratio {
  background: inherit;
  -webkit-filter: blur(0.5px);
  -moz-filter: blur(0.5px);
  -ms-filter: blur(0.5px);
  filter: blur(0.5px);
  -ms-mask-box-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB2aWV3Qm94PSIwIDAgMSAxIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIj48bGluZWFyR3JhZGllbnQgaWQ9Imxlc3NoYXQtZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPjxzdG9wIG9mZnNldD0iNDUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjEiLz48c3RvcCBvZmZzZXQ9IjcwJSIgc3RvcC1jb2xvcj0icmdiKDAsMCwwKSIgc3RvcC1vcGFjaXR5PSIwIi8+PHN0b3Agb2Zmc2V0PSI4NCUiIHN0b3AtY29sb3I9InJnYigwLDAsMCkiIHN0b3Atb3BhY2l0eT0iMCIvPjxzdG9wIG9mZnNldD0iOTUlIiBzdG9wLWNvbG9yPSIjMDAwMDAwIiBzdG9wLW9wYWNpdHk9IjEiLz48L2xpbmVhckdyYWRpZW50PjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjbGVzc2hhdC1nZW5lcmF0ZWQpIiAvPjwvc3ZnPg==);
  -webkit-mask-box-image: -webkit-linear-gradient(
    top,
    #000000 45%,
    transparent 70%,
    transparent 84%,
    #000000 95%
  );
  -moz-mask-box-image: -moz-linear-gradient(
    top,
    #000000 45%,
    transparent 70%,
    transparent 84%,
    #000000 95%
  );
  -o-mask-box-image: -o-linear-gradient(
    top,
    #000000 45%,
    transparent 70%,
    transparent 84%,
    #000000 95%
  );
  mask-box-image: linear-gradient(
    to bottom,
    #000000 45%,
    transparent 70%,
    transparent 84%,
    #000000 95%
  );
}
.responsive-pitch-fx-old-timey.responsive-pitch-fx
  .responsive-pitch-fx-aspect-ratio {
  background: inherit;
  -webkit-filter: grayscale(100%) sepia(10%);
  -moz-filter: grayscale(100%) sepia(10%);
  -ms-filter: grayscale(100%) sepia(10%);
  filter: grayscale(100%) sepia(10%);
  -ms-mask-box-image: none;
  -webkit-mask-box-image: none;
  -moz-mask-box-image: none;
  -o-mask-box-image: none;
  mask-box-image: none;
}
.responsive-pitch-fx .responsive-pitch-fx-render,
.responsive-pitch-fx .responsive-pitch-fx-svg,
.responsive-pitch-fx .responsive-pitch-fx-dom {
  position: absolute;
  top: 0;
  left: 0;
  font-size: inherit;
  width: 100%;
  height: 100%;
  font-family: inherit;
  opacity: 0;
  z-index: 30;
}
.responsive-pitch-fx .responsive-pitch-fx-trails {
  z-index: 40;
}
.responsive-pitch-fx .responsive-pitch-fx-strikezone {
  z-index: 40;
}
.responsive-pitch-fx .responsive-pitch-fx-dom {
  z-index: 60;
}
[data-responsive-pitch-fx-diagnostics='true'].responsive-pitch-fx
  .responsive-pitch-fx-dom::after {
  content: attr(data-sz-top) ' x ' attr(data-sz-bot);
  display: block;
  position: absolute;
  top: 75%;
  left: 0;
  text-align: center;
  width: 100%;
  font: inherit;
  font-size: 18px;
  color: #000;
  font-weight: bold;
}
.responsive-pitch-fx .responsive-pitch-fx-ball {
  position: absolute;
  font-weight: bold;
  background-color: rgba(255, 255, 255, 0);
  top: 100%;
  left: 100%;
  border-radius: 50%;
  border: 1px solid transparent;
  line-height: 150%;
  vertical-align: middle;
  text-shadow: 1px 1px 1px #000;
  -webkit-background-position: 75% 75%;
  -ms-background-position: 75% 75%;
  -moz-background-position: 75% 75%;
  background-position: 75% 75%;
}
.responsive-pitch-fx .responsive-pitch-fx-player {
  width: 19%;
  height: 0;
  padding: 0;
  padding-bottom: 38.689516129032256%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center bottom;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* For player height scaling */

  -webkit-transform-origin: 75% 100% 0;
  -moz-transform-origin: 75% 100% 0;
  -o-transform-origin: 75% 100% 0;
  -ms-transform-origin: 75% 100% 0;
  transform-origin: 75% 100% 0;
  z-index: 20;
}
.lefty.responsive-pitch-fx .responsive-pitch-fx-player {
  -webkit-transform-origin: 25% 100% 0;
  -moz-transform-origin: 25% 100% 0;
  -o-transform-origin: 25% 100% 0;
  -ms-transform-origin: 25% 100% 0;
  transform-origin: 25% 100% 0;
}
.responsive-pitch-fx-old-timey.responsive-pitch-fx .responsive-pitch-fx-player {
  -webkit-filter: grayscale(100%) sepia(10%);
  -moz-filter: grayscale(100%) sepia(10%);
  -ms-filter: grayscale(100%) sepia(10%);
  filter: grayscale(100%) sepia(10%);
}
[data-responsive-pitch-fx-diagnostics='true'].responsive-pitch-fx
  .responsive-pitch-fx-player::after {
  content: attr(data-player-height);
  display: block;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font: inherit;
  font-size: 18px;
  color: #fff;
  font-weight: bold;
}
.responsive-pitch-fx .responsive-pitch-fx-render,
.responsive-pitch-fx .responsive-pitch-fx-svg,
.responsive-pitch-fx .responsive-pitch-fx-dom,
.responsive-pitch-fx .responsive-pitch-fx-player {
  -webkit-transition: opacity 300ms ease-in 0ms;
  -moz-transition: opacity 300ms ease-in 0ms;
  -o-transition: opacity 300ms ease-in 0ms;
  transition: opacity 300ms ease-in 0ms;
  opacity: 1;
}
.responsive-pitch-fx
  .responsive-pitch-fx-render[data-responsive-pitch-fx-hide='true'],
.responsive-pitch-fx
  .responsive-pitch-fx-svg[data-responsive-pitch-fx-hide='true'],
.responsive-pitch-fx
  .responsive-pitch-fx-dom[data-responsive-pitch-fx-hide='true'],
.responsive-pitch-fx
  .responsive-pitch-fx-player[data-responsive-pitch-fx-hide='true'],
[data-responsive-pitch-fx-hide='true'].responsive-pitch-fx
  .responsive-pitch-fx-render,
[data-responsive-pitch-fx-hide='true'].responsive-pitch-fx
  .responsive-pitch-fx-svg,
[data-responsive-pitch-fx-hide='true'].responsive-pitch-fx
  .responsive-pitch-fx-dom,
[data-responsive-pitch-fx-hide='true'].responsive-pitch-fx
  .responsive-pitch-fx-player {
  opacity: 0;
}
.responsive-pitch-fx .responsive-pitch-fx-render.responsive-pitch-fx-trails {
  opacity: 0.8;
}
.responsive-pitch-fx-data-description {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
  visibility: hidden;
  min-width: 210px;
  opacity: 0;
  color: #fff;
  background: #000;
  font-family: 'gd-primary';
  font-size: 13px;
  text-align: left;
  padding: 16px;
  line-height: 18px;
  z-index: -9999;
}
