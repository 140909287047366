.notification-container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
  pointer-events: none;
  &.is-disabled {
    background-color: hsla(0, 0%, 95%, 0.5);
    pointer-events: inherit;
  }
}

#notification {
  position: absolute;
  left: 50%;
  top: 10%;
  width: 40%;
  transform: translate(-50%);
}
