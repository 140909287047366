#loader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
  background-color: hsla(0, 0%, 95%, 0.5);
}

#observe {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 25;
  background-color: hsla(0, 0%, 95%, 0.5);
}

#spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}

#lock {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}
