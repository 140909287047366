#observe {
  position: fixed;
  left: 0;
  top: 46.8px;
  bottom: 0;
  right: 0;
  z-index: 30;
  background-color: hsla(0, 0%, 95%, 0.5);
  pointer-events: none;
  border: solid 5px $success;
  &.correcting {
    border: solid 5px $danger;
    background-color: hsla(0, 0%, 95%, 0.1);
  }
  &.locked {
    pointer-events: inherit;
    background-color: hsla(0, 0%, 95%, 0.4);
  }
}
