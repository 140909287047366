@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/functions';

$modal-content-width: 800px;
$mlb-blue: #041e42;
$mlb-blue-invert: findColorInvert($mlb-blue);
$mlb-red: #b12842;
$mlb-red-invert: findColorInvert($mlb-red);
$danger: $mlb-red;
$danger-invert: findColorInvert($danger);
$lime: lime;
$lime-invert: findColorInvert($lime);
$orange: orange;
$orange-invert: findColorInvert($orange);
$light: hsl(0, 0%, 94%);
$yellow: #eed202;

// fix for 0.8.0
$control-height: 2.25em;
$button-padding-horizontal: 0.75em;

@import '~bulma/sass/utilities/derived-variables';

$tabs-toggle-link-border-color: hsl(0, 0%, 71%);
$tabs-toggle-link-active-background-color: $dark;
$tabs-toggle-link-active-border-color: $dark;

$grey-invert: findColorInvert($grey);
$grey-light-invert: findColorInvert($grey-light);
$grey-lighter-invert: findColorInvert($grey-lighter);
$grey-lightest: lighten($grey-lighter, 5%);
$grey-lightest-invert: findColorInvert($grey-lightest);

$info-light: lighten($info, 40%);
$info-light-invert: findColorInvert($info-light);
$success-light: lighten($success, 40%);
$success-light-invert: findColorInvert($success-light);
$maroon: #82312f;
$maroon-invert: findColorInvert($maroon);
$mlbColors: (
  'mlb-blue': (
    $mlb-blue,
    $mlb-blue-invert,
  ),
  'mlb-red': (
    $mlb-red,
    $mlb-red-invert,
  ),
  'lime': (
    $lime,
    $lime-invert,
  ),
  'grey': (
    $grey,
    $grey-invert,
  ),
  'grey-light': (
    $grey-light,
    $grey-light-invert,
  ),
  'grey-lighter': (
    $grey-lighter,
    $grey-lighter-invert,
  ),
  'grey-lightest': (
    $grey-lightest,
    $grey-lightest-invert,
  ),
  'info-light': (
    $info-light,
    $info-light-invert,
  ),
  'success-light': (
    $success-light,
    $success-light-invert,
  ),
  'orange': (
    $orange,
    $orange-invert,
  ),
  'maroon': (
    $maroon,
    $maroon-invert,
  ),
);

$colors: map-merge($colors, $mlbColors);
