$player__padding: 0.5rem !important;

.player {
  @extend .box;
  @extend .p-2;
  margin: 0 !important;
  border-radius: 0 !important;
  display: flex;

  &.is-batter {
    // background-color: darken($link-light, 5%);
    background-color: lighten($link, 28%);
  }

  &.is-pitcher {
    // @extend .has-background-success-light;
    background-color: lighten($success, 28%);
  }
}

.player__headshot {
  position: relative;
  width: 40px;
}

.player__info {
  padding-left: $player__padding;
  flex: 1;
}

.headshot__image {
  width: 100%;
  height: auto;
  border-radius: 50%;
  opacity: 0.4;
}
//
.headshot__jersey {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5rem;
}
